/* Add a black background color to the top navigation */
.mfly-navbar {
  background-color: var(--blue);
  overflow: hidden;

  a {
    float: left;
    display: block;
    color: white;
    text-align: center;
    padding: 20px 20px;
    text-decoration: none;
    font: 15px "Lato", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    height: 100%;

    &:hover:not(.active) {
      color: var(--maize);
    }

    &.active {
      background-color: var(--maize);
    }
  }

  a.social {
    float: right;
  }

  .icon {
    display: none;
  }

  .logo {
    vertical-align: middle;
    padding: 6px;
    padding-left: 15px;
    padding-right: 15px;

    img {
      height: 2em;
    }
  }
}

@media screen and (max-width: 768px) {
  .mfly-navbar a:not(:first-child) {
    display: none;
  }
  .mfly-navbar a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .mfly-navbar.responsive {
    position: relative;

    a.icon {
      position: absolute;
      right: 0;
      top: 0;
    }

    a {
      float: none;
      display: block;
      text-align: left;
    }
  }
}
