.Home {
  color: white;

  h1 {
    color: white;
  }

  .bgimg-team {
    background-image: url("./../../img/home/team_banner.jpg");
    background-color: #193148;
    background-blend-mode: overlay;
    min-height: 100vh;
  }

  .bgimg-all {
    background-image: url("./../../img/home/all_planes.jpg");
    background-color: #193148;
    background-blend-mode: overlay;
    min-height: 100vh;
  }

  .bgimg-hilift {
    background-image: url("./../../img/home/hi_lift_banner.jpg");
    background-color: #586a7b;
    background-blend-mode: overlay;
    min-height: 400px;
  }

  .bgimg-advanced {
    background-image: url("./../../img/home/advanced_banner.jpg");
    min-height: 400px;
  }

  .bgimg-autonomous {
    background-image: url("./../../img/home/auton_banner.jpg");
    background-color: #586a7b;
    background-blend-mode: overlay;
    min-height: 400px;
  }

  .caption {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
  }

  .caption span.border {
    background-color: var(--maize);
    color: var(--blue);
    padding: 18px;
    font-size: 25px;
    letter-spacing: 10px;
    line-height: 75px;
  }

  .logo {
    max-height: 200px;
    max-width: 80vw;
  }

  .text-center {
    margin-top: 10px;
  }

  /* Turn off parallax scrolling for tablets and phones */
  @media only screen and (max-device-width: 1024px) {
    .bgimg {
      background-attachment: scroll;
    }
  }
}
