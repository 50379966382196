.Sponsors {
  margin-top: 50px;
  margin-bottom: 150px;

  .blue-div {
    text-align: center;
  }
  .white-div {
    padding: 25px 0px 0px 0px;
  }

  .sponsors-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      max-height: 200px;
      max-width: 100%;
      padding: 10px;

      &.tier-Platinum {
        max-height: 150px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .txt {
      padding-left: 20%;
      padding-right: 20%;
    }
  }

  .donate-btn {
    background-color: var(--blue);;
    color: #fff;
    text-shadow: none;
    box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
    background-image: none;
    font-size: 1.5rem;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: 0;
    border: none;
    vertical-align: baseline;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    user-select: none;
    transition: background-color 0.5s ease;

    &:hover {
      background-color: var(--maize);;
      color: #fff;
      text-shadow: none;
    }
  }
  body {
    padding-bottom: 50px;
  }
}
