.Members {
    margin-top: 50px;
    margin-bottom: 150px;
}

.img {
    padding-top: 20px;
    max-width: 60%;
    
}

.text-center {
    margin-top: 50px;
    margin-bottom: 30px;
}

.card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
}

.card-item {
    display: flex;
    padding: 1rem;
}

@media (min-width: 40rem) {
    .card-item {
        width: 75%;
    }
}

@media (min-width: 768px) {
    .card-item {
        width: 300px;
    }
}

.card {
    min-width: 300px;
    min-height: 500px; 
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden; 
}

.card-image {
    height: 350px;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
    transition: transform 0.5s ease;
}

.card-text {
    padding: 1rem;
    padding-top: 0;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-bottom: 1.25rem;
    font-weight: 400;
}

.card-title {
    padding-left: 1rem;
    padding-top: 1rem;
    font-size: 1.1rem;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin: 0px;

    a {
        text-decoration: none;
        color: var(--blue);

        &:hover {
            opacity: 0.8;
        }
    }
}

.card-subtitle {
    display: block;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0px;
    color: #aaa;
}

a:hover .card-image {
    transform: scale(1.05) translateY(-2.3%);
}
