.Contact {
  margin-top: 50px;
  margin-bottom: 150px;
  
  .gcal iframe {
    border-width: 0;
    resize: both;
    overflow-x: scroll;
  }

  .poster {
    width: 500px;
    max-width: 100%;
  }

  h2 {
    font: 22px "Lato", sans-serif;
    color: var(--blue);
    margin-bottom: 0;
  }
}
