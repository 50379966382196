.About {
  color: white;

  .bgimg-mission {
    background-image: url("./../../img/about/banner.jpg");
    background-color: #586a7b;
    background-blend-mode: overlay;
    background-position: center; 
    min-height: 500px;
  }

  .bgimg-subteams {
    background-image: url("./../../img/about/banner.jpg");
    background-color: #586a7b;
    background-blend-mode: overlay;
    min-height: 400px;
  }

  .caption {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
  }

  .caption span.border {
    background-color: var(--maize);
    color: var(--blue);
    padding: 18px;
    font-size: 25px;
    letter-spacing: 10px;
    line-height: 75px;
  }

  .logo {
    max-height: 200px;
    max-width: 80vw;
  }

  .subteam-description {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .text-center {
    margin-top: 10px;
  }

  h3 {
    letter-spacing: 5px;
    text-transform: uppercase;
    font: 30px "Lato", sans-serif;
    color: #111;
  }

  /* Turn off parallax scrolling for tablets and phones */
  @media only screen and (max-device-width: 1024px) {
    .bgimg {
      background-attachment: scroll;
    }
  }
}
