.Aircraft {
  color: white;

  .white-div {
    h1 {
      color: var(--blue);
    }
  }

  .blue-div {
    h1 {
      color: white;
    }
  }

  h1 {
    font-size: 48px;

    &.aircraft-name {
      font-size: 64px;
      margin-top: -10px;
    }
  }

  .stats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .stat {
      text-align: center;
      min-width: 300px;

      h1 {
        margin-bottom: 0;
      }

      p {
        text-transform: uppercase;
        font-size: 1.2rem;
      }
    }
  }
}
