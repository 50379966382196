.text-center {
  text-align: center;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.ox {
  overflow-x: hidden;
}

.bgimg {
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.img {
  max-width: 40vw;
  padding: 0px 50px 0px 50px;

  &.float-left {
    padding-left: 0;
  }

  &.float-right {
    padding-right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .img {
    max-width: 80vw;
    float: none !important;
    padding: 50px 0px 50px 0px;
  }
}

.white-div {
  color: #777;
  background-color: white;
  text-align: center;
  padding: 50px 80px;
  text-align: left;
}

.blue-div {
  color: #ddd;
  background-color: var(--blue);
  text-align: center;
  padding: 50px 80px;
  text-align: left;

  h1 {
    color: #fff !important;
  }

  h3 {
    color: #fff !important;
  }
}

.description-center {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 25px;
  margin-bottom: 25px;
}

h1 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font: 32px "Lato", sans-serif;
  color: var(--blue);
}

h3 {
  letter-spacing: 5px;
  text-transform: uppercase;
  font: 32px "Lato", sans-serif;
  color: var(--blue);
}

p {
  font-size: 22px;
  line-height: 1.5;
}
